import React from "react";
import Header from "./Header";
import { Link, NavLink } from "react-router-dom";
import logo from '../../assets/image/halal-logo.png'

const SidebarLayout = (props) => {

  const location = window.location
  const user = location.pathname.match(/^\/users/) ? "active" : "";
  const restaurant = location.pathname.match(/^\/restaurant/) ? "active" : "";
  const category = location.pathname.match(/^\/category/) ? "active" : "";
  const discount = location.pathname.match(/^\/discount/) ? "active" : "";
  const banner = location.pathname.match(/^\/banner/) ? "active" : "";
  const notification = location.pathname.match(/^\/notification/) ? "active" : "";
  const ingredient = location.pathname.match(/^\/ingredient/) ? "active" : "";
  const appingredient = location.pathname.match(/^\/upload/) ? "active" : "";
  const cms = location.pathname.match(/^\/cms/) ? "active" : "";
  const product = location.pathname.match(/^\/product/) ? "active" : "";
  const uploadres = location.pathname.match(/^\/res-upload/) ? "active" : "";
  const country = location.pathname.match(/^\/country/) ? "active" : "";
  const report = location.pathname.match(/^\/report/) ? "active" : "";
  const reports = location.pathname.match(/^\/admin-banner/) ? "active" : "";
  const cuisine=location.pathname.match(/^\/menu-report/) ? "active" : "";
  const cupon=location.pathname.match(/^\/cupon-report/) ? "active" : "";
  const favreports = location.pathname.match(/^\/fav-resturent/) ? "active" : "";
  const diet = location.pathname.match(/^\/diet-report/) ? "active" : "";
  const clam = location.pathname.match(/^\/clam-list/) ? "active" : "";
  const feedback=location.pathname.match(/^\/feedback-list/) ? "active" : "";
  const contactUs=location.pathname.match(/^\/contact-us-list/)? "active":"";
  const businessClaims=location.pathname.match(/^\/business-claim-list/)? "active":"";




  return (<>
    <Header />
<div className="Adjust">
  <div className="Sidebar">
      <aside className="navbar-aside navbar-aside_custom" id="offcanvas_aside">
        <div className="aside-top border-bottom">
          <Link to="/users" className="brand-wrap">
            <div class="admin_logo">
              <img src={logo} class="img-fluid login_logo" alt="#" />
            </div>
          </Link>
        </div>
        <nav className="n-bar-cutom">
          <ul className="menu-aside">
          
          <li className="menu-item">
              <NavLink
                activeclassname="active "
                className={`menu-link`}
                to="/dashboard"
                exact={true}>
                <i className="fa fa-tachometer"></i>
                <span className="text">Dashboard</span>
              </NavLink>
            </li>
             <li className="menu-item">
              <NavLink
                activeclassname="active "
                className={`menu-link ${category}`}
                to="/category"
                exact={true}>
                <i className="fa fa-list-alt"></i>
                <span className="text">Restaurants Categories</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeclassname="active "
                className={`menu-link ${discount}`}
                to="/discount"
                exact={true}>
                <i className="fa fa-gift"></i>
                <span className="text">Discounts / Coupons</span>
              </NavLink>
            </li>
             <li className="menu-item">
              <NavLink
                activeclassname="active "
                className={`menu-link ${restaurant}`}
                to="/restaurant"
                exact={true}>
                <i className="fa fa-utensils"></i>
                <span className="text">Manage Restaurants</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeclassname="active "
                className={`menu-link ${ingredient}`}
                to="/ingredient"
                exact={true}>
                <i className="fas fa-cheese"></i>
                <span className="text">Manage Ingredients</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeclassname="active "
                className={`menu-link ${appingredient}`}
                to="/upload/ingredient"
                exact={true}>
                <i className="fas fa-cheese"></i>
                <span className="text">Manage Upload Ingredients</span>
              </NavLink>
            </li>
            
            <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${user}`}
                to="/users">
                <i className="fa fa-user-friends"></i>
                <span className="text">Manage App Users </span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${banner}`}
                to="/banner">
                <i className="fa fa-camera-retro"></i>
                <span className="text">Manage Banners</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${notification}`}
                to="/notification">
                <i className="fa fa-bullhorn"></i>
                <span className="text">News / Announcement</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${cms}`}
                to="/cms">
                <i className="fa fa-globe"></i>
                <span className="text">Manage Cms</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${product}`}
                to="/product">
                <i className="fa fa-shopping-cart"></i>
                <span className="text">Manage Products</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${uploadres}`}
                to="/res-upload">
                <i className="fa fa-upload"></i>
                <span className="text">User Added Restaurants</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${country}`}
                to="/country">
                <i className="fa fa-globe"></i>
                <span className="text">Manage Country</span>
              </NavLink>
            </li>

            <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${report}`}
                to="/report">
                <i className="fa fa-cloud-download"></i>
                <span className="text">Restaurant Reports</span>
              </NavLink>
            </li>

            <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${reports}`}
                to="/admin-banner">
                <i className="fa fa-cloud-download"></i>
                <span className="text">Banner Reports</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${cuisine}`}
                to="/menu-report">
                <i className="fa fa-cloud-download"></i>
                <span className="text">Cuisine Reports</span>
              </NavLink>
            </li>

            <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${cupon}`}
                to="/cupon-report">
                <i className="fa fa-cloud-download"></i>
                <span className="text">Coupon Reports</span>
              </NavLink>
            </li>
           
             <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${favreports}`}
                to="/fav-resturent">
                <i className="fa fa-heart"></i>
                <span className="text">Favorite Restaurant</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${diet}`}
                to="/diet-report">
                <i className="fa fa-cloud-download"></i>
                <span className="text">Diet Reports</span>
              </NavLink>
            </li>

            <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${clam}`}
                to="/clam-list">
                <i className="fa fa-exclamation-circle"></i>
                <span className="text">Restaurant Claims</span>
              </NavLink>
            </li>

            <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${feedback}`}
                to="/feedback-list">
                <i className="fa fa-comments"></i>
                <span className="text">Manage Users Feedback</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${contactUs}`}
                to="/contact-us-list">
                <i className=" fa fa-address-card"></i>
                <span className="text">Manage Contact Us</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
               activeclassname="active "
               className={`menu-link ${businessClaims}`}
                to="/business-claim-list">
                <i className="fa fa-briefcase"></i>
                <span className="text">Business Claims</span>
              </NavLink>
            </li>
           
          </ul>
          <br />
          <br />
        </nav>
      </aside>
    </div>
    
    <div className="Content">

<div className="child">
  {props.children} 
  </div>
  </div>
</div>
  </>);
}

export default SidebarLayout;